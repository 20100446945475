module.exports = {
    SYSTEM_ADMINISTRATOR: 'SYSTEM_ADMINISTRATOR',
    SYS_ADMINISTRATOR: 'SYSTEM_ADMINISTRATOR',
    NETWORK_ADMINISTRATOR: 'NETWORK_ADMINISTRATOR',
    DEVELOPER: 'DEVELOPER',
    PRIVACY_OFFICER: 'PRIVACY_OFFICER',
    WEB_DEVELOPER: 'WEB_DEVELOPER',
    DEVOPS_ENGINEER: 'DEVOPS_ENGINEER',
    IT_MANAGER: 'IT_MANAGER',
    LEGAL_COMPLIANCE_OFFICER: 'LEGAL_COMPLIANCE_OFFICER',
    IT_INFRASTRUCTURE_MANAGER: 'IT_INFRASTRUCTURE_MANAGER',
    DNS_ADMINISTRATOR: 'DNS_ADMINISTRATOR',
    SECURITY_TEAM: 'SECURITY_TEAM',
    NETWORK_ENGINEER: 'NETWORK_ENGINEER',
    INFRASTRUCTURE_ENGINEER: 'INFRASTRUCTURE_ENGINEER',
    NOT_AVAILABLE:'NOT_AVAILABLE'
}
