export default {
    async checkTrialExists(productId, feature) {
        if (productId && feature) {
            const response = await fetch(`${Window.$apiUrl}/api/v1/rest/products/module-features/${productId}/${feature}`, {
                method: 'GET',
                credentials: 'include'
            })
            if (response.ok) {
                return await response.json()
            }
        }
        return false
    },
    async startTrial(productId, feature) {
        if (productId && feature) {
            const response = await fetch(`${Window.$apiUrl}/api/v1/rest/products/module-features/${productId}/${feature}/start-trial`, {
                method: 'PUT',
                credentials: 'include'
            })
            if (response.ok) {
                return await response.json()
            }
        }
        return false
    },
    async requestFeature(productId, feature) {
        if (productId && feature) {
            const response = await fetch(`${Window.$apiUrl}/api/v1/rest/products/module-features/${productId}/${feature}`, {
                method: 'PUT',
                credentials: 'include'
            })
            if (response.ok) {
                return await response.json()
            }
        }
        return false
    }
}
