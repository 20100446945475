import dayjs from 'dayjs'

export function throttle(func, delay) {
    let lastCalled = 0
    return (...args) => {
        let now = new Date().getTime()
        if (now - lastCalled < delay) {
            return
        }
        lastCalled = now
        return func(...args)
    }
}

/**
 * Wrapper function that accepts a dayjs unit and an amount.
 * It then calculates the time range from (now - amount of unit) to now,
 * and returns the incident analysis data for that period.
 *
 * @param {Array} incidents - Array of incident objects.
 * @param {string} unit - Dayjs time unit (e.g., "day", "month", etc.).
 * @param {number} amount - Amount of the given unit.
 * @returns {Object} - Object containing { hourlyData, dailyUptime, weeklyUptime, monthlyUptime }
 */
export function analyzeIncidentDataWithTimeUnit(incidents, startDate, unit, amount) {
    // Using dayjs to determine the analysis period.
    const now = startDate
    const startTime = now.subtract(amount, unit)
    // Calculate the number of full hours between startTime and now.
    const numberOfHours = now.diff(startTime, 'hour')

    // Call the original analysis function.
    return this.analyzeIncidentData(incidents, startTime.toISOString(), numberOfHours)
}

/* --- Previous function --- */

// Helper to compute uptime percentage.
// without unknown state data
export function calcPercentage(obj) {
    const total = obj.up + obj.down
    return total > 0 ? (obj.up / total) * 100 : -1
}

/**
 * Analyzes incident data and returns hourly stats and uptime percentages by day, week, and month.
 * Each hourly element is of the form:
 * { up: ms, down: ms, unknown: ms, stepStartDate: Date, stepEndDate: Date }
 *
 * @param {Array} incidents - Array of incident objects.
 * @param {string|Date} startDate - Analysis start time.
 * @param {number} numberOfHours - How many hours to analyze.
 * @returns {Object} - Object containing { hourlyData, dailyUptime, weeklyUptime, monthlyUptime }
 */
export function analyzeIncidentData(incidents, startDate, numberOfHours) {
    const startTime = new Date(startDate).getTime()
    const endTime = startTime + numberOfHours * 3600000 // 1 hour = 3600000 ms

    // Sort incidents by incidentDate.
    incidents.sort((a, b) => new Date(a.incidentDate) - new Date(b.incidentDate))

    // Determine initial state:
    let initialState = 'unknown'
    for (let i = incidents.length - 1; i >= 0; i--) {
        const incidentTime = new Date(incidents[i].incidentDate).getTime()
        if (incidentTime <= startTime) {
            initialState = this.getStateFromIncident(incidents[i])
            break
        }
    }

    // Filter incidents within the analysis period.
    const relevantIncidents = incidents.filter((incident) => {
        const t = new Date(incident.incidentDate).getTime()
        return t >= startTime && t <= endTime
    })

    // Build timeline segments.
    let segments = []
    let currentState = initialState
    let currentTime = startTime

    for (let incident of relevantIncidents) {
        const incidentTime = new Date(incident.incidentDate).getTime()
        if (incidentTime > currentTime) {
            segments.push({start: currentTime, end: incidentTime, state: currentState})
        }
        currentState = this.getStateFromIncident(incident)
        currentTime = incidentTime
    }
    if (currentTime < endTime) {
        segments.push({start: currentTime, end: endTime, state: currentState})
    }

    // Create hourly data.
    let hourlyData = []
    for (let hourStart = startTime; hourStart < endTime; hourStart += 3600000) {
        const hourEnd = Math.min(hourStart + 3600000, endTime)
        let hourMetrics = {
            up: 0,
            down: 0,
            unknown: 0,
            stepStartDate: new Date(hourStart),
            stepEndDate: new Date(hourEnd)
        }

        segments.forEach((segment) => {
            const overlapStart = Math.max(hourStart, segment.start)
            const overlapEnd = Math.min(hourEnd, segment.end)
            if (overlapStart < overlapEnd) {
                const duration = overlapEnd - overlapStart
                if (segment.state === 'up') {
                    hourMetrics.up += duration
                } else if (segment.state === 'down') {
                    hourMetrics.down += duration
                } else {
                    hourMetrics.unknown += duration
                }
            }
        })
        hourlyData.push(hourMetrics)
    }

    // Group hourly data by day, week, and month.
    let daily = {}
    let weekly = {}
    let monthly = {}

    hourlyData.forEach((hour) => {
        const date = hour.stepStartDate
        const dayKey = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
        const weekKey = this.getWeekKey(date)
        const monthKey = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`

        if (!daily[dayKey]) daily[dayKey] = {up: 0, down: 0, unknown: 0}
        if (!weekly[weekKey]) weekly[weekKey] = {up: 0, down: 0, unknown: 0}
        if (!monthly[monthKey]) monthly[monthKey] = {up: 0, down: 0, unknown: 0}

        daily[dayKey].up += hour.up
        daily[dayKey].down += hour.down
        daily[dayKey].unknown += hour.unknown

        weekly[weekKey].up += hour.up
        weekly[weekKey].down += hour.down
        weekly[weekKey].unknown += hour.unknown

        monthly[monthKey].up += hour.up
        monthly[monthKey].down += hour.down
        monthly[monthKey].unknown += hour.unknown
    })

    let up = 0
    let down = 0
    let unknown = 0
    const dailyUptimeData = Object.keys(daily).map((key) => {
        const data = {
            period: key,
            ...daily[key],
            percentage: calcPercentage(daily[key])
        }

        up += data.up
        down += data.down
        unknown += data.unknown

        return data
    })

    const weeklyUptimeData = Object.keys(weekly).map((key) => {
        const data = {
            period: key,
            ...weekly[key],
            percentage: calcPercentage(weekly[key])
        }
        return data
    })

    const monthlyUptimeData = Object.keys(monthly).map((key) => {
        const data = {
            period: key,
            ...monthly[key],
            percentage: calcPercentage(monthly[key])
        }

        return data
    })

    return {
        hourlyData,
        percentage: calcPercentage({up, down, unknown}),
        up: up,
        down: down,
        unknown: unknown,
        daily: {data: dailyUptimeData},
        weekly: {data: weeklyUptimeData},
        monthly: {data: monthlyUptimeData}
    }
}

/**
 * Helper function that derives the state from an incident.
 * If incident.status contains "ONLINE" (even if in maintenance) then it's "up".
 * If it contains "OFFLINE" then it's "down".
 * Otherwise, returns "unknown".
 */
export function getStateFromIncident(incident) {
    if (incident.status.toUpperCase().includes('ONLINE')) {
        return 'up'
    } else if (incident.status.toUpperCase().includes('OFFLINE')) {
        return 'down'
    }
    return 'unknown'
}

/**
 * Helper function to generate an ISO week key (e.g., "2025-W08")
 * based on a given Date.
 */
export function getWeekKey(date) {
    const target = new Date(date.valueOf())
    const dayNr = (target.getDay() + 6) % 7
    target.setDate(target.getDate() - dayNr + 3)
    const firstThursday = new Date(target.getFullYear(), 0, 4)
    const diff = target - firstThursday
    const weekNumber = 1 + Math.round(diff / (7 * 24 * 3600000))
    return `${target.getFullYear()}-W${weekNumber.toString().padStart(2, '0')}`
}

export function createDurations(uptimeData, duration, interval) {
    const hourlyData = uptimeData.hourlyData.map((d) => ({
        ...d,
        stepStartDate: dayjs(d.stepStartDate),
        stepEndDate: dayjs(d.stepEndDate)
    }))

    // Calculate the earliest start boundary
    const startDate = hourlyData[hourlyData.length - 1].stepEndDate.subtract(interval, duration)
    const results = []

    // Single pass pointer to avoid filtering each time
    let pointer = 0
    // Skip anything before our overall start period
    while (pointer < hourlyData.length && hourlyData[pointer].stepStartDate.isBefore(startDate)) {
        pointer++
    }

    for (let i = 0; i < interval; i++) {
        const startRange = startDate.add(i, duration)
        const endRange = startDate.add(i + 1, duration)

        let up = 0
        let down = 0
        let unknown = 0
        const segmentData = []

        // Move pointer forward while inside [startRange, endRange)
        while (pointer < hourlyData.length && !hourlyData[pointer].stepStartDate.isSameOrAfter(endRange)) {
            const rowStart = hourlyData[pointer].stepStartDate
            // If this row is within our current range, add to sums
            if (rowStart.isSameOrAfter(startRange)) {
                segmentData.push(hourlyData[pointer])
                up += hourlyData[pointer].up
                down += hourlyData[pointer].down
                unknown += hourlyData[pointer].unknown
            }
            pointer++
        }

        // Build result object
        if (segmentData.length > 0) {
            const total = up + down + unknown
            const stepStartDate = segmentData[0].stepStartDate
            const stepEndDate = segmentData[segmentData.length - 1].stepEndDate
            results.push({
                up,
                down,
                unknown,
                stepStartDate,
                stepEndDate,
                hourlyData: segmentData,
                upPercentage: ((up / total) * 100).toFixed(2),
                downPercentage: ((down / total) * 100).toFixed(2),
                unknownPercentage: ((unknown / total) * 100).toFixed(2)
            })
        } else {
            // If no data in this segment, just push default zeros
            results.push({
                up: 0,
                down: 0,
                unknown: 0,
                stepStartDate: startRange,
                stepEndDate: endRange,
                hourlyData: [],
                upPercentage: '0.00',
                downPercentage: '0.00',
                unknownPercentage: '0.00'
            })
        }
    }

    return results
}
