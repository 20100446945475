import i18next from 'i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .init(
        {
            supportedLngs: ['en', 'de'], // map en-US to en
            nonExplicitSupportedLngs: false, // default setting used with above
            fallbackLng: 'en',
            seo: true,
            backend: {
                loadPath: `${Window.$apiUrl}/api/rest/srcLang/{{lng}}/{{ns}}.json`,
                allowMultiLoading: false,
                crossDomain: true,
                overrideMimeType: true,
                seo: true
            },
            debug: false,
            ns: ['translation'],
            defaultNS: 'translation',
            detection: {
                order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
                lookupQuerystring: 'lng',
                lookupCookie: 'i18next',
                lookupLocalStorage: 'i18nextLng',
                lookupSessionStorage: 'i18nextLng',

                // cache user language
                caches: ['cookie', 'localStorage'],
                excludeCacheFor: ['cimode']
            }
        },
        (error, t) => {
            if (error) console.error(error)
        }
    )

export default i18next
