<template>
    <div class="v-tour">
        <transition name="fade">
            <v-step
                v-if="steps[currentStep] && stepVisible"
                :key="currentStep"
                :step="steps[currentStep]"
                :previous-step="previousStep"
                :next-step="nextStep"
                :stop="stopFromStep"
                :skip="skip"
                :is-first="isFirst"
                :is-last="isLast"
                :highlight="true"
                :stop-on-fail="false"
                :enabled-buttons="customOptions.enabledButtons"
                :debug="customOptions.debug"
                :isMovingBackwards="isMovingBackwards"
                :needsSkipAlreadyShownStepsWorkaround="needsSkipAlreadyShownStepsWorkaround"
                @targetNotFound="$emit('targetNotFound', $event)"
                @created="setStepCreated"
            >
                <template>
                    <div slot="header"></div>

                    <v-card slot="content" class="mx-auto" width="400">
                        <v-card-title v-if="steps[currentStep].header">
                            {{ $t(steps[currentStep].header.title, steps[currentStep].header.titleParams) }}</v-card-title
                        >

                        <v-card-subtitle>
                            <!-- predefined data -->
                            <div v-html="$t(steps[currentStep].content, steps[currentStep].contentParams)"></div>
                        </v-card-subtitle>

                        <v-card-text class="text--primary">
                            <v-progress-linear color="var(--v-success-base)" :value="((currentStep + 1.0) / steps.length) * 1.0 * 100.0"> </v-progress-linear>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn v-if="!isLast" text @click="skip"> {{ $t('tours.buttons.skip') }} </v-btn>

                            <v-btn v-if="!isFirst" text @click="previousStep"> {{ $t('tours.buttons.back') }} </v-btn>

                            <v-btn v-if="!isLast" color="primary" elevation="0" @click="nextStep"> {{ $t('tours.buttons.next') }} </v-btn>

                            <v-btn v-if="isLast" @click="stop()"> {{ $t('tours.buttons.close') }} </v-btn>
                        </v-card-actions>
                    </v-card>

                    <div slot="actions"></div>
                </template>
            </v-step>
        </transition>
        <!-- default z-index value is 2000 - but we cannot draw over dialog (202) due to z-index containers preventing highlight to show -->
        <v-overlay v-model="isRunning" z-index="201"></v-overlay>
    </div>
</template>

<script>
import {DEFAULT_CALLBACKS, DEFAULT_OPTIONS, KEYS} from '../shared/constants'
import {delay, waitForElement} from '../shared/functions'
import {mapState} from 'vuex'
import sum from 'hash-sum'

// tours
import {CyberRiskAnalysisTour} from '@/components/tours/steps/CyberRiskAnalysisTour'
import {CyberRiskAnalysisReadonlyTour} from '@/components/tours/steps/CyberRiskAnalysisReadonlyTour'
import {VendorRiskManagementTour} from '@/components/tours/steps/VendorRiskManagementTour'
import {VRMSupplierRegistrationTour} from '@/components/tours/steps/VRMSupplierRegistrationTour'

export default {
    name: 'v-tour',
    props: {
        name: {
            type: String
        },
        options: {
            type: Object,
            default: () => {
                return DEFAULT_OPTIONS
            }
        },
        callbacks: {
            type: Object,
            default: () => {
                return DEFAULT_CALLBACKS
            }
        }
    },
    data() {
        return {
            steps: [],
            originalSteps: [],
            currentStep: -1,
            overlay: false,
            dialogOverlays: [],
            tour: {},
            keyTimeout: 0,
            stepCreated: 2,
            isMovingBackwards: false,
            needsSkipAlreadyShownStepsWorkaround: false,
            stepObserver: undefined
        }
    },
    created() {
        //this.$store.commit('loadTourDoNotShow')
    },
    mounted() {
        this.$store.commit('setTourBaseElement', this)
        window.addEventListener('popstate', this.handleBackButton)

        // load all tour steps into state
        this.$store.commit('addTour', CyberRiskAnalysisTour)
        this.$store.commit('addTour', CyberRiskAnalysisReadonlyTour)
        this.$store.commit('addTour', VendorRiskManagementTour)
        // this.$store.commit('addTour', VRMSupplierRegistrationTour)

        // start one observer when the v-tour is mounted in App.vue
        this.stepObserver = new MutationObserver((mutations) => {
            if (this.$store.state.tours.stepsToObserve?.length) {
                const nodesAdded = mutations.some((mutation) => {
                    return mutation.type === 'childList' && mutation.addedNodes.length
                })
                if (nodesAdded) {
                    const arr = this.$store.state.tours.stepsToObserve
                    for (let i = 0; i < arr.length; i++) {
                        if (this.$store.state.tours.stepsToUnobserve[arr[i].stepId]) continue

                        // shallow copy because we add targetElement to it
                        const step = Object.assign({}, arr[i])

                        if (!step || typeof step.target !== 'string') continue

                        const target = document.querySelector(step.target)
                        if (target) {
                            step.targetElement = target
                            step.isObserved = true
                            this.$store.dispatch('addSingleTourStep', step)
                            //arr[i] = undefined
                            this.$store.state.tours.stepsToUnobserve[step.stepId] = 1
                        }
                    }
                }
            }
        })

        this.stepObserver.observe(this.$parent.$el, {
            childList: true,
            subtree: true
        })
    },
    beforeDestroy() {
        window.removeEventListener('popstate', this.handleBackButton)

        // Remove the keyup listener if it has been defined
        if (this.customOptions.useKeyboardNavigation) {
            window.removeEventListener('keyup', this.handleKeyup)
        }
    },
    computed: {
        // IDEA: currentStep in state -> needs Setter
        /*...mapState({
            currentStep: (state) => state.tours.currentStep
        }),*/
        // Allow us to define custom options and merge them with the default options.
        // Since options is a computed property, it is reactive and can be updated during runtime.
        customOptions() {
            return {
                ...DEFAULT_OPTIONS,
                ...this.options
            }
        },
        customCallbacks() {
            return {
                ...DEFAULT_CALLBACKS,
                ...this.callbacks
            }
        },
        // Return true if the tour is active, which means that there's a VStep displayed
        isRunning() {
            return this.currentStep > -1 && this.currentStep < this.numberOfSteps
        },
        isFirst() {
            return this.currentStep === 0
        },
        isLast() {
            return this.currentStep === this.steps.length - 1
        },
        numberOfSteps() {
            return this.steps.length
        },
        step() {
            return this.steps[this.currentStep]
        },
        stepVisible() {
            return !this.$store.state.tours.isNavigating
        }
    },
    methods: {
        handleBackButton() {
            if (this.isRunning) {
                this.stop(true)
            }
        },
        async start(startStep) {
            // Register keyup listeners for this tour
            if (this.customOptions.useKeyboardNavigation) {
                window.addEventListener('keyup', this.handleKeyup)
            }
            // Wait for the DOM to be loaded, then start the tour
            startStep = typeof startStep !== 'undefined' ? parseInt(startStep, 10) : 0
            let step = this.steps[startStep]

            // wait for first step to appear - single steps via directive already have their element available
            let targetAvailable = step.target
            if (typeof step.target === 'string') targetAvailable = await waitForElement(step.target)
            /*if (!targetAvailable) {
                // do not start if not available (optional, uncomment to implement)
                this.stop(true)
                return Promise.resolve()
            }*/

            let process = () =>
                new Promise((resolve, reject) => {
                    setTimeout(() => {
                        this.customCallbacks.onStart()
                        this.currentStep = startStep
                        resolve()
                    }, this.customOptions.startTimeout)
                })

            // avoid click on single step (better always avoid click on first?)
            if (this.steps.length > 1) {
                await this.setClickHandler(step)
            }
            // permanent help steps can always be in dialog, so check it anyways
            await this.disableDialogs()

            if (typeof step.before !== 'undefined') {
                try {
                    await step.before('start')
                } catch (e) {
                    return Promise.reject(e)
                }
            }
            await process()
            return Promise.resolve()
        },
        async previousStep() {
            this.clearStepsToRemove()

            this.isMovingBackwards = true

            let futureStep = this.currentStep - 1

            // cannot further move backwards so change direction to get to the next available step
            if (futureStep < 1) this.isMovingBackwards = false

            let process = () =>
                new Promise((resolve, reject) => {
                    this.customCallbacks.onPreviousStep(this.currentStep)
                    this.currentStep = futureStep
                    resolve()
                })
            if (futureStep > -1) {
                const step = this.steps[futureStep]
                const currStep = this.steps[this.currentStep]

                if (typeof currStep.navigateBack !== 'undefined' && !step.isObserved) {
                    try {
                        const clickTarget = document.querySelector(currStep.navigateBack)
                        if (clickTarget) {
                            this.$store.commit('setTourIsNavigating', true)
                            clickTarget.click()
                            await waitForElement(step.target, this.$t('tours.waitForSpinnerText'))
                            await delay(DEFAULT_OPTIONS.navigationDelay) // wait x seconds for sub page to be loaded, TODO: hook for page loaded
                            this.$store.commit('setTourIsNavigating', false)
                        }
                    } catch (e) {
                        return Promise.reject(e)
                    }
                }

                this.setClickHandler(step)
                if (step.inDialog) await this.disableDialogs()

                if (typeof step.before !== 'undefined') {
                    try {
                        await step.before('previous')
                    } catch (e) {
                        return Promise.reject(e)
                    }
                }
                await process()
            }
            return Promise.resolve()
        },
        async nextStep() {
            this.clearStepsToRemove()

            this.isMovingBackwards = false

            let futureStep = this.currentStep + 1
            let process = () =>
                new Promise((resolve, reject) => {
                    this.customCallbacks.onNextStep(this.currentStep)
                    this.currentStep = futureStep
                    resolve()
                })
            if (futureStep < this.numberOfSteps && this.currentStep !== -1) {
                const step = this.steps[futureStep]

                if (typeof step.navigate !== 'undefined' && !step.isObserved) {
                    try {
                        const clickTarget = document.querySelector(step.navigate)
                        if (clickTarget) {
                            this.$store.commit('setTourIsNavigating', true)
                            clickTarget.click()
                            await waitForElement(step.target, this.$t('tours.waitForSpinnerText'))
                            await delay(DEFAULT_OPTIONS.navigationDelay) // wait x seconds for sub page to be loaded, TODO: hook for page loaded
                            this.$store.commit('setTourIsNavigating', false)
                        }
                    } catch (e) {
                        return Promise.reject(e)
                    }
                }

                await this.setClickHandler(step)
                if (step.inDialog) await this.disableDialogs()

                if (typeof step.before !== 'undefined') {
                    try {
                        await step.before('next')
                    } catch (e) {
                        return Promise.reject(e)
                    }
                }
                await process()
            } else if (futureStep >= this.numberOfSteps) {
                // last step was not found stop the tour
                this.stop()
            }
            return Promise.resolve()
        },
        async disableDialogs() {
            const dialog = await waitForElement('.v-dialog.v-dialog--active .dialog', undefined, 250)
            if (dialog) {
                // restore dialog settings
                const dialogPointerEvents = dialog.style.pointerEvents
                dialog.style.pointerEvents = 'none'
                // add pseudo overlay
                const childOverlay = dialog.getElementsByClassName('guided-tour-pseudo-overlay')
                if (childOverlay.length == 0) {
                    const overlay = document.createElement('div')
                    overlay.classList.add('guided-tour-pseudo-overlay')
                    overlay.dialogPointerEvents = dialogPointerEvents
                    overlay.parentDialog = dialog
                    dialog.append(overlay)
                    this.dialogOverlays.push(overlay)
                }
            }
        },
        async setSingleClickHandler(step, target, clickOnce, clickDelay, requireClick = false) {
            let clickTarget
            if (requireClick) {
                clickTarget = await waitForElement(target, this.$t('tours.waitForSpinnerText'), step.waitFor ?? DEFAULT_OPTIONS.targetTimeout)
            } else {
                clickTarget = document.querySelector(target)
            }
            if (clickTarget) {
                // prevent from being clicked twice but allow another step to click it again
                // clickTarget.customKey = step.stepId + target;
                // only allow one click so we can go back and forth
                clickTarget.customKey = target
                clickTarget.clickOnce = clickOnce
                this.$store.dispatch('applyTourClick', clickTarget)
                if (clickDelay) {
                    await delay(clickDelay)
                }
            }
        },
        async setClickHandler(step) {
            if (step.showPermanentHelpStep) return
            // to disable clicks in observed single tour steps - could make it more robust
            //if (step.isObserved) return

            // click target
            if (typeof step.click !== 'undefined') {
                if (Array.isArray(step.click)) {
                    for (let click of step.click) {
                        await this.setSingleClickHandler(step, click.target, click.once, click.delay, click.required)
                    }
                } else {
                    await this.setSingleClickHandler(step, step.click, step.clickOnce, step.clickDelay, step.clickRequired)
                }
            }
        },
        clearStepsToRemove() {
            // remove steps from dialogs, etc.
            // adjust current Step accordingly
            // also save to do not show after tour
            for (const stepId of this.$store.state.tours.tourStepsToRemove) {
                const idx = this.steps.findIndex((x) => x.inDialog && !x.target.isConnected && x.stepId == stepId)
                if (idx > -1) {
                    this.steps.splice(idx, 1)
                    if (idx < this.currentStep) {
                        this.currentStep--
                    }
                }

                // is not yet in steps but was about to show
                delete this.$store.state.tours.singleTourSteps[stepId]
            }

            this.$store.commit('clearTourStepsToRemove')
        },
        stopFromStep() {
            // else calling passing stop to the step would pass the event to the stop function
            stop(false)
        },
        stop(showAgain = false) {
            this.customCallbacks.onStop()

            // save tour states
            if (!showAgain) {
                // check if we have seen all steps
                const unshownSteps = this.steps.filter((step) => {
                    return !this.$store.state.tours.doNotShowAgain[step.stepId]
                })

                // now rank them with priority
                for (let i = 0; i < unshownSteps.length; i++) {
                    unshownSteps[i].priority = unshownSteps.length - i
                }

                this.$store.commit('clearObservedTourSteps')
                this.$store.commit('observeTourSteps', unshownSteps)
            }
            this.$store.commit('setCurrentTour', undefined)
            this.$store.commit('resetAllowShowAgain')

            // remove all highlights from document

            document.body.classList.remove('v-tour--active')
            this.currentStep = -1
            this.steps.length = 0

            this.dialogOverlays.forEach((overlay) => {
                try {
                    overlay.parentDialog.style.pointerEvents = overlay.dialogPointerEvents
                    overlay.remove()
                } catch (e) {
                    //console.log(e);
                }
            })

            document.querySelectorAll('.wfe-spinner')?.forEach((el) => el.remove())
        },
        skip() {
            // set all to do not show
            this.$store.commit(
                'doNotShowTourStepsAgain',
                this.steps.map((x) => x.stepId)
            )

            this.customCallbacks.onSkip()
            this.stop()
        },
        finish() {
            this.customCallbacks.onFinish()
            this.stop()
        },
        handleKeyup(e) {
            if (this.customOptions.debug) {
                // console.log('[Vue Tour] A keyup event occured:', e)
            }

            switch (e.keyCode) {
                case KEYS.ARROW_RIGHT:
                    // throttle (we combine the Tour.nextStep with the step.created function thus 2)
                    if (this.stepCreated < 2 || this.currentStep == this.numberOfSteps - 1) break
                    this.isKeyEnabled('arrowRight') &&
                        this.nextStep()
                            .then(() => this.stepCreated++)
                            .catch(() => this.stepCreated++)
                    this.stepCreated = 0
                    break
                case KEYS.ARROW_LEFT:
                    // throttle
                    if (this.stepCreated < 2) break
                    this.isKeyEnabled('arrowLeft') &&
                        this.previousStep()
                            .then(() => this.stepCreated++)
                            .catch(() => this.stepCreated++)
                    if (this.currentStep > 0) this.stepCreated = 0
                    break
                case KEYS.ESCAPE:
                    if (this.isRunning) {
                        this.isKeyEnabled('escape') && this.stop()
                        // let the user in peace until the next refresh/login/mounted
                        this.stepObserver.disconnect()
                    } else if (this.$store.state.tours.permanentHelpVisible) {
                        // hides if it is shown
                        this.$store.dispatch('showAllTourStepsInUI')
                    }
                    break
            }
        },
        isKeyEnabled(key) {
            const {enabledNavigationKeys} = this.customOptions
            return enabledNavigationKeys.hasOwnProperty(key) ? enabledNavigationKeys[key] : true
        },
        setStepCreated() {
            this.stepCreated++
        },
        async refresh() {
            const prev = this.currentStep
            this.currentStep = -1
            setTimeout(() => {
                this.currentStep = prev
            }, 0)
        },
        async canStartTour(tour) {
            if (!tour) return true
            if (tour.tourName === 'default') return true

            let canStartTour = true
            if (tour.requiresElement !== undefined) {
                const elem = await waitForElement(tour.requiresElement)
                canStartTour = elem != null
            }

            // show unshown steps
            // check if tour contains unshown steps else do not show
            const stepsToShow = tour.steps.filter((step) => {
                return !this.$store.state.tours.doNotShowAgain[step.stepId]
            })

            if (stepsToShow.length == 0) {
                return false
            }

            // have new steps been added that were not yet shown
            if (stepsToShow.length < tour.steps.length) {
                // this happens with F5
                this.$store.commit('observeTourSteps', stepsToShow)
                const newSteps = stepsToShow.filter((x) => !tour.steps.some((y) => x.stepId === y.stepId))
                if (newSteps.length > 0) {
                    // also add the new steps
                    this.$store.commit('observeTourSteps', newSteps)
                }
                return false
            }

            return canStartTour
        }
    }
}
</script>

<style lang="scss">
body.v-tour--active {
    pointer-events: none;
}

.v-tour {
    pointer-events: auto;
}

.v-tour__target--relative {
    position: relative;
}
/* overrides - hide vue-tour default - instead show vuetify card*/
.vue-tour .v-step {
    background: none;
    color: inherit;
    max-width: 320px;
    border-radius: 3px;
    box-shadow: none;
    padding: 0;
    pointer-events: auto;
    text-align: inherit;
    z-index: 10000;
}

/* overrides arrow color */
.vue-tour .v-step__arrow--dark:before {
    background: white;
}

/* vue tour: do not scroll to edge of view */
.vue-tour-target {
    scroll-margin-top: 10rem;
}

@keyframes glowing {
    from {
        box-shadow: 0 0 1rem 0.25rem #36ddcf4f;
    }
    to {
        box-shadow: 0 0 1rem 2.5rem #36ddcf4f; /* the CSS variable comes in too late, else we could do rgba(var(--v-success-base), 0.3); */
    }
}

/* vue tour dark background overlay - as recommended by maintainers */
.v-tour__target--highlighted {
    /* disable click as this will confuse the tour atm*/
    pointer-events: none !important;
    z-index: 9999 !important;
    /*box-shadow: 0 0 0 99999px rgba(0,0,0,.75);*/
    animation: glowing 1300ms alternate infinite; /* infinite  - save CPU resources when dev*/
    /* when using custom focus cursor */
    /* box-shadow: inherit */
    background: white;
}

@keyframes focus-cursor-animation {
    from {
        transform: scale(100%, 100%);
    }
    to {
        transform: scale(150%, 150%);
    }
}

.v-tour__target--padding {
    padding: 1rem;
}

/* wait for element - wfe */
.wfe-spinner {
    color: white;
    border-radius: 1rem;
    z-index: 999;
    font-weight: bold;
    text-shadow: 0 0 3px black;
    background-color: #000a;
    padding: 1rem;
}

.wfe-loader {
    width: 3rem;
    height: 3rem;
    border: 0.33rem solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.wfe-text {
    display: inline-block;
    box-sizing: border-box;
    margin-left: 1rem;
}
</style>
