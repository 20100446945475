<template>
    <v-dialog v-model="dialog" width="600">
        <v-card>
            <v-card-title class="text-h5">
                {{ $t('npsDialog.title') }}
            </v-card-title>
            <v-card-text class="mt-5">
                {{ $t('npsDialog.text', {solution: $t(`npsDialog.solutions.${solution}`)}) }}

                <div class="mt-12 d-flex justify-space-between">
                    <v-btn elevation="0" fab small :color="buttonColor(rating)" v-for="rating of availableRatings" @click="selectedRating = rating">{{
                        rating
                    }}</v-btn>
                </div>
                <div class="mt-2 d-flex justify-space-between">
                    <small>{{ $t('npsDialog.notLikely') }}</small>
                    <small>{{ $t('npsDialog.veryLikely') }}</small>
                </div>
                <div class="mt-2 text-center validation-error" v-if="ratingError">{{ $t('npsDialog.ratingError') }}</div>

                <div class="mt-5">
                    <label>{{ $t('npsDialog.label') }}:</label>
                    <v-textarea
                        outlined
                        name="input-7-4"
                        :placeholder="$t('npsDialog.placeholder')"
                        v-model="comments"
                        counter="8000"
                        :rules="[(v) => (v || '').length <= 8000 || $t('npsDialog.textLimitError')]"
                    ></v-textarea>
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-space-between">
                <v-btn text @click="skip()">
                    {{ $t('npsDialog.buttons.skip') }}
                </v-btn>
                <v-btn color="primary" elevation="0" @click="saveFeedback()">
                    {{ $t('npsDialog.buttons.submit') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import dayjs from 'dayjs'
import NpsRequests from '../../helper/NpsRequests'

export default {
    name: 'NpsDialog',
    props: {
        newProductOrdered: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            selectedRating: undefined,
            availableRatings: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            comments: null,
            dialog: false,
            saveFunction: null,
            solution: null,
            colorCodes: [
                'red darken-4',
                'red darken-2',
                'red',
                'amber darken-4',
                'amber lighten-2',
                'amber lighten-1',
                'lime lighten-1',
                'green lighten-3',
                'green lighten-2',
                'green lighten-1',
                'green'
            ],
            ratingError: false
        }
    },
    watch: {
        $route: function () {
            if (this.$route.name == 'VendorRiskManagement') {
                this.checkVRMInfo()
            } else if (this.$route.name == 'ReportPage') {
                this.checkCyberRiskAnalysisInfo()
            } else if (this.$route.name == 'CyberriskAnalysisOverview') {
                const {taskId} = this.$route.params
                this.checkManagementInfo(taskId)
            }
        },
        newProductOrdered: function () {
            if (this.newProductOrdered) {
                this.checkManagementInfo()
            }
        },
        dialog() {
            if (!this.dialog) {
                this.reset()
            }
        },
        selectedRating() {
            if (typeof this.selectedRating == 'number') {
                this.ratingError = false
            }
        }
    },
    computed: {
        ...mapGetters(['currentUser'])
    },
    methods: {
        async checkVRMInfo() {
            if (this.shouldSkipDisplay()) return
            const {productId} = this.$route.params
            if (productId) {
                const response = await NpsRequests.getInfoForVRM(productId)
                if (response) {
                    this.dialog = true
                    this.saveFunction = 'vrm'
                    this.solution = 'vrms'
                }
            }
        },
        async checkManagementInfo(taskId) {
            if (this.shouldSkipDisplay()) return
            const response = await NpsRequests.getInfoForManagement(taskId)
            if (response) {
                this.dialog = true
                this.saveFunction = 'management'
                this.solution = 'partnership'
            }
        },
        async checkCyberRiskAnalysisInfo() {
            if (this.shouldSkipDisplay()) return
            const {scanId} = this.$route.params
            if (scanId) {
                const response = await NpsRequests.getInfoForCyberRiskAnalysis(scanId)

                if (response) {
                    this.dialog = true
                    this.saveFunction = 'cyberriskAnalysis'
                    this.solution = 'cyberriskanalysis'
                }
            }
        },
        async saveFeedback() {
            if (typeof this.selectedRating != 'number') {
                this.ratingError = true
                return
            }
            if (this.selectedRating > 10 || (this.comments && this.comments.length > 8000)) {
                return
            }
            const data = {
                rating: this.selectedRating,
                comments: this.comments
            }
            if (this.saveFunction == 'vrm') {
                const {productId} = this.$route.params
                if (productId) {
                    const response = await NpsRequests.saveVRMFeedback(productId, data)
                    if (response) {
                        this.reset()
                    }
                }
            } else if (this.saveFunction == 'cyberriskAnalysis') {
                const {scanId} = this.$route.params
                if (scanId) {
                    const response = await NpsRequests.saveCyberRiskAnalysisFeedback(scanId, data)
                    if (response) {
                        this.reset()
                    }
                }
            } else if (this.saveFunction == 'management') {
                const response = await NpsRequests.saveManagementFeedback(data)
                if (response) {
                    this.reset()
                }
            }
        },
        reset() {
            this.selectedRating = undefined
            this.comments = null
            this.dialog = false
            this.saveFunction = null
            this.solution = null
            this.ratingError = false
        },
        shouldSkipDisplay() {
            const storedDate = localStorage.getItem('skipFeedbackDate')
            if (storedDate) {
                return dayjs().isSame(dayjs(storedDate), 'day')
            } else {
                return false
            }
        },
        skip() {
            localStorage.setItem('skipFeedbackDate', dayjs())
            this.reset()
        },
        buttonColor(rating) {
            if (typeof this.selectedRating == 'number' && rating <= this.selectedRating) {
                return 'grey lighten-1' //this.colorCodes[rating]
            }
            return 'default'
        }
    }
}
</script>

<style scoped></style>
