<template>
    <v-dialog v-model="dialog" :max-width="maxWidth" @input="cancel()">
        <v-card>
            <v-card-title class="headline">
                {{ header }}
            </v-card-title>

            <v-card-text>
                <h2>
                    <strong>{{ question }}</strong>
                </h2>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="submit()">
                    {{ $t('questionDialog.yes') }}
                </v-btn>
                <v-btn color="green darken-1" text @click="cancel()">
                    {{ $t('questionDialog.no') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'QuestionDialog',
    props: {
        header: {
            type: String,
            required: false
        },
        question: {
            type: String,
            required: false
        },
        questionValue: {
            required: false
        },
        maxWidth: {
            type: String,
            default: '900px',
            required: false
        }
    },
    data: function() {
        return {
            dialog: true
        }
    },
    methods: {
        submit() {
            this.$emit('submit', this.questionValue ? this.questionValue : false)
            this.dialog = false
        },
        cancel() {
            this.$emit('cancel', false)
            this.dialog = false
        }
    }
}
</script>
<style scoped>
</style>
