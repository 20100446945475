const ModuleFeatures = {
    // cyberrisk analysis
    DOMAIN_SQUATTING: 'domainSquatting',
    UPTIME_MONITORING: 'uptimeMonitoring',
    AI_HELPER: 'aiHelper',
    COMPLIANCE_MAPPING: 'complianceMapping',
    RESOURCE_CALCULATOR: 'resourceCalculator',

    // management preview
    COMPLETE_PREVIEW: 'completePreview',

    // vendor risk management
    CUSTOM_MESSAGES: 'customMessages',

    SECURE_PLUS: 'securePlus'
}
Object.freeze(ModuleFeatures)

module.exports = ModuleFeatures
