import Vue from 'vue'

export default {
    setScanData(state, data) {
        Vue.set(state, 'scanData', data)
    },

    setComplianceMappingFilter(state, data) {
        Vue.set(state.scanData, 'complianceMappingFilter', data)
    },

    setComplianceStandards(state, data) {
        Vue.set(state.scanData, 'complianceStandards', data)
    },

    setComplianceFilterToggle(state, data) {
        Vue.set(state.scanData, 'complianceFilterToggle', data)
    }
}
