import Vue from 'vue'
import sum from 'hash-sum'
import { setOwnUserProperties } from '../../services/UserService'

function saveObjectKeysToLocalStorage(storageKey, obj) {
    localStorage.setItem(storageKey, JSON.stringify(Object.keys(obj)))
}

async function saveDoNotShowTourAgain(state) {
    saveObjectKeysToLocalStorage('doNotShowTourAgain', state.tours.doNotShowAgain)
    localStorage.setItem('tourStepsToObserve', JSON.stringify(state.tours.stepsToObserve))

    await setOwnUserProperties({
        displayedTourSteps: Object.keys(state.tours.doNotShowAgain),
        remainingTourSteps: state.tours.stepsToObserve?.map(s => s.stepId) ?? []
    })
}

export default {
    addTour(state, tour) {
        // generate stepId for each included tour step where non was provided
        tour.steps.forEach((step) => {
            if (!step.stepId) step.stepId = sum(step.title + step.content)
            step.tourName = tour.tourName // we could also show all remaining tour steps based on their tour
        })
        tour.tourId = sum(tour.tourName)

        Vue.set(state.tours.tours, tour.tourName, tour)
    },
    insertStepsIntoTour(state, param) {
        if (param['currentStep'] > -1) {
            if (state.tours.currentTour === 'default'
                && !param['steps'].some((step) => step.fromDirective)) {
                // we sort all steps with priority
                state.tours.tourBase.steps.push(...param['steps'])
                state.tours.tourBase.steps.sort((a, b) => b.priority - a.priority)

                // re-create step in tour
                state.tours.tourBase.refresh()
            } else {
                // sort ascending priority, because we insert inverted
                // higher priority will come first step
                param['steps']
                    .sort((a, b) => a.priority - b.priority)
                    .forEach((step) => {
                        // insert after current step
                        state.tours.tourBase.steps.splice(param['currentStep'] + 1, 0, step)
                    })
            }
        } else {
            // here it is the expected ordering
            state.tours.tourBase.steps.push(...param['steps'].sort((a, b) => b.priority - a.priority))
        }
    },
    removeStepFromRunningTour(state, stepId) {
        state.tours.tourStepsToRemove.push(stepId)
    },
    clearTourStepsToRemove(state) {
        state.tours.tourStepsToRemove.length = 0
    },
    setSingleTourSteps(state, steps) {
        Vue.set(state.tours, 'singleTourSteps', steps)
    },
    setAwaitTourTimeoutId(state, id) {
        Vue.set(state.tours, 'awaitTourTimeoutId', id)
    },
    addSingleTourStepInternal(state, step) {
        Vue.set(state.tours.singleTourSteps, step.stepId, step)
    },
    clearSingleTourSteps(state) {
        Vue.set(state.tours, 'singleTourSteps', {})
        clearTimeout(state.tours.awaitTourTimeoutId)
        Vue.set(state.tours, 'awaitTourTimeoutId', 0)
    },
    setTourAboutToStart(state, name) {
        Vue.set(state.tours, 'tourAboutToStart', name)
    },
    setTourClick(state, clickTargetKey) {
        Vue.set(state.tours.clickTargets, clickTargetKey, true)
    },
    setTourIsNavigating(state, isNavigating) {
        Vue.set(state.tours, 'isNavigating', isNavigating)

        // reset tour clicks - because we navigate and must probably click again
        Vue.set(state.tours, 'clickTargets', {})
    },
    setTourBaseElement(state, elem) {
        Vue.set(state.tours, 'tourBase', elem)
    },
    setCurrentTour(state, tourName) {
        Vue.set(state.tours, 'currentTour', tourName)
    },
    doNotShowTourAgain(state, id) {
        Vue.set(state.tours.doNotShowAgain, id, 1)
        saveDoNotShowTourAgain(state)
    },
    doNotShowTourStepAgain(state, stepId) {
        Vue.set(state.tours.doNotShowAgain, stepId, 1)
        saveDoNotShowTourAgain(state)
    },
    doNotShowTourStepsAgain(state, idList) {
        for (const id of idList) {
            Vue.set(state.tours.doNotShowAgain, id, 1)
        }
        saveDoNotShowTourAgain(state)
    },
    loadTourDoNotShow(state, userProperties) {
        if (!userProperties?.displayedTourSteps) {
            const val = localStorage.getItem('doNotShowTourAgain')
            if (val) {
                const valArray = JSON.parse(val)
                const res = {}
                valArray.forEach((key) => {
                    res[key] = 1
                })
                Vue.set(state.tours, 'doNotShowAgain', res)
            }
            const arr = localStorage.getItem('tourStepsToObserve')
            if (arr) {
                Vue.set(state.tours, 'stepsToObserve', JSON.parse(arr))
            }
        } else {
            const res = {}
            userProperties.displayedTourSteps.forEach((key) => {
                res[key] = 1
            })
            Vue.set(state.tours, 'doNotShowAgain', res)

            let remaining = []
            if (userProperties.remainingTourSteps?.length) {
                const allSteps = Object.values(state.tours.tours).flat()
                remaining = allSteps.filter(s => userProperties.remainingTourSteps.includes(s.stepId))
            }

            Vue.set(state.tours, 'stepsToObserve',  remaining)
        }
    },
    resetTourDoNotShow(state, resetObject = {}) {
        Vue.set(state.tours, 'doNotShowAgain', resetObject)
        Vue.set(state.tours, 'stepsToObserve', [])
        saveDoNotShowTourAgain(state) // actually async

        // reset tour clicks
        Vue.set(state.tours, 'clickTargets', {})
    },
    setAllowShowAgain(state, id) {
        Vue.set(state.tours.allowShowAgain, id, 1)
    },
    resetAllowShowAgain(state) {
        Vue.set(state.tours, 'allowShowAgain', {})
    },
    observeTourSteps(state, steps) {
        for (let step of steps) {
            step.targetElement = undefined
        }
        state.tours.stepsToObserve.push(...steps)
        // clear all steps that have been shown (else F5 refresh would whipe them when we just unobserve)
        Vue.set(state.tours, 'stepsToObserve', state.tours.stepsToObserve.filter((step) => !state.tours.doNotShowAgain[step.stepId]))
        saveDoNotShowTourAgain(state)
    },
    clearObservedTourSteps(state) {
        Vue.set(state.tours, 'stepsToUnobserve', {})
    },
    resetTourStepsToObserve(state) {
        Vue.set(state.tours, 'stepsToObserve', [])
        saveDoNotShowTourAgain()
    },
    mountStep(state, step) {
        state.tours.mountedSteps.push(step)
    },
    unmountStep(state, step) {
        const index = state.tours.mountedSteps.indexOf(step)
        if (index > -1) {
            state.tours.mountedSteps.splice(index, 1)
        }
    },
}
