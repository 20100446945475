<template>
    <v-dialog
        v-model="dialog"
        width="750"
        @input="
            () => {
                selectedFeature = null
                dialog = false
            }
        "
        v-if="selectedFeature"
    >
        <v-overlay :value="loading" z-index="90000">
            <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
        </v-overlay>
        <v-card>
            <v-card-title class="text-h5">
                {{ $t('upsellDialogSecurePlus.title') }}
            </v-card-title>
            <v-card-text class="mt-5">
                <v-list three-line>
                    <v-subheader>
                        {{ $t('upsellDialogSecurePlus.description') }}
                    </v-subheader>
                    <v-list-item
                        v-for="(feature, index) of Object.keys(features)"
                        :key="feature + index"
                        :class="feature == selectedFeature ? 'selected-feature' : ''"
                    >
                        <v-list-item-avatar>
                            <v-icon x-large>{{ features[feature].icon }}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ features[feature].title }}</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ features[feature].subtitle }}
                                <a v-if="features[feature].link" :href="features[feature].link.url" target="_blank" rel="noopener noreferrer">
                                    {{ features[feature].link.text }}
                                </a>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-text v-if="showNonAdminMessage" class="text-center">
                {{ $t('upsellDialogSecurePlus.nonAdminMessage') }}
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-space-between">
                <v-btn text outlined @click="dialog = false">
                    {{ $t('upsellDialogSecurePlus.buttons.close') }}
                </v-btn>
                <v-btn v-if="showAdminButton" color="primary" elevation="0" @click="requestPurchase">
                    {{ $t('upsellDialogSecurePlus.buttons.purchase') }}
                </v-btn>
                <v-btn v-if="showAdminButton && !trialExists" color="primary" elevation="0" @click="startTrial">
                    {{ $t('upsellDialogSecurePlus.buttons.try') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar app top v-model="showSuccess" timeout="5000" color="success" style="z-index: 99999">
            <span style="color: white">{{ successMessage }}</span>
        </v-snackbar>
        <v-snackbar app top v-model="showError" timeout="5000" color="error" style="z-index: 99999">
            <span style="color: white">{{ errorMessage }}</span>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import ModuleFeaturesRequests from '../helper/ModuleFeaturesRequests'
import ModuleFeatures from '../../../models/models/enums/ModuleFeatures'

export default {
    name: 'UpsellInfoDialog',
    data() {
        return {
            dialog: false,
            features: {},
            selectedFeature: null,
            productId: null,
            loading: false,
            showSuccess: false,
            showError: false,
            successMessage: null,
            errorMessage: null,
            trialExists: true
        }
    },
    computed: {
        ...mapGetters(['currentUser']),
        showNonAdminMessage() {
            return this.currentUser && this.currentUser.grants && this.currentUser.grants.hasOwnProperty('1') && this.currentUser.grants['1'] === false
        },
        showAdminButton() {
            return this.currentUser && this.currentUser.grants && this.currentUser.grants.hasOwnProperty('1') && this.currentUser.grants['1'] === true
        },
        show: {
            get() {
                return this.dialog
            },
            set(value) {
                if (!value) {
                    this.reset()
                }
                this.dialog = value
            }
        }
    },
    methods: {
        async open(feature, productId) {
            const hasTrials = await Promise.all([
                ModuleFeaturesRequests.checkTrialExists(productId, ModuleFeatures.SECURE_PLUS),
                ModuleFeaturesRequests.checkTrialExists(productId, ModuleFeatures.COMPLIANCE_MAPPING),
                ModuleFeaturesRequests.checkTrialExists(productId, ModuleFeatures.AI_HELPER),
                ModuleFeaturesRequests.checkTrialExists(productId, ModuleFeatures.DOMAIN_SQUATTING)
            ])
            this.trialExists = hasTrials.includes(true)
            if (feature && productId) {
                this.features = {
                    [ModuleFeatures.AI_HELPER]: {
                        icon: 'mdi-robot',
                        title: this.$t('upsellDialogSecurePlus.features.aiHelper.title'),
                        subtitle: this.$t('upsellDialogSecurePlus.features.aiHelper.description'),
                        link: {
                            url: 'https://azure.microsoft.com/de-de/products/ai-services/openai-service',
                            text: this.$t('upsellDialogSecurePlus.features.aiHelper.link')
                        }
                    },
                    [ModuleFeatures.COMPLIANCE_MAPPING]: {
                        icon: 'mdi-gavel',
                        title: this.$t('upsellDialogSecurePlus.features.complianceMapping.title'),
                        subtitle: this.$t('upsellDialogSecurePlus.features.complianceMapping.description'),
                        link: undefined
                    },
                    [ModuleFeatures.DOMAIN_SQUATTING]: {
                        icon: 'mdi-hook',
                        title: this.$t('upsellDialogSecurePlus.features.domainSquatting.title'),
                        subtitle: this.$t('upsellDialogSecurePlus.features.domainSquatting.description'),
                        link: undefined
                    }
                }
                this.selectedFeature = feature
                this.productId = productId
                this.dialog = true
            }
        },
        reset() {
            this.selectedFeature = null
            this.productId = null
            this.successMessage = null
            this.errorMessage = null
        },
        async requestPurchase() {
            this.loading = true
            const response = await ModuleFeaturesRequests.requestFeature(this.productId, ModuleFeatures.SECURE_PLUS)
            if (response) {
                this.successMessage = this.$t('upsellDialogSecurePlus.message.purchaseRequest.success', {
                    featureName: this.$t(`upsellDialogSecurePlus.title`)
                })
                this.showSuccess = true
            } else {
                this.errorMessage = this.$t('upsellDialogSecurePlus.message.purchaseRequest.error', {
                    featureName: this.$t(`upsellDialogSecurePlus.title`)
                })
                this.showError = true
            }
            setTimeout(() => {
                this.reset()
                this.loading = false
                this.dialog = false
            }, 5100)
        },
        async startTrial() {
            this.loading = true
            const response = await ModuleFeaturesRequests.startTrial(this.productId, ModuleFeatures.SECURE_PLUS)
            if (response) {
                this.successMessage = this.$t('upsellDialogSecurePlus.message.trialStart.success', {
                    featureName: this.$t(`upsellDialogSecurePlus.title`)
                })
                this.showSuccess = true
            } else {
                this.errorMessage = this.$t('upsellDialogSecurePlus.message.trialStart.error', {
                    featureName: this.$t(`upsellDialogSecurePlus.title`)
                })
                this.showError = true
            }
            setTimeout(() => {
                this.reset()
                this.loading = false
                if (response) window.location.reload()
            }, 5100)
        }
    }
}
</script>

<style scoped>
.v-list-item__subtitle {
    line-height: 1.5 !important;
}

.selected-feature {
    background-color: #dedede !important;
}
</style>
