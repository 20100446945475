<template>
    <div>
        <UserDialog @save="(user) => saveUser(user)" v-if="showUserDialog" value :editOwnUser="true" @input="showUserDialog = false" />
        <v-menu offset-y>
            <template v-slot:activator="{on, attrs}">
                <v-icon v-bind="attrs" v-on="on" style="color: white">mdi-cog</v-icon>
            </template>
            <v-list>
                <v-list-item v-for="(item, index) in contextItems" :key="index" v-on:click="contextAction(item.action)">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import UserDialog from '@/views/users/UserDialog'

export default {
    name: 'UserMenu',
    components: {UserDialog},
    data() {
        return {
            showUserDialog: false
        }
    },
    methods: {
        ...mapActions(['updateUser', 'logout']),

        contextAction(action, id) {
            switch (action) {
                case 'editUserSettings': {
                    this.showUserDialog = true
                    break
                }
                case 'resetShownTours': {
                    this.$store.commit('resetTourDoNotShow')

                    setTimeout(() => window.location.reload(), 1000) // make changes visible
                    break
                }
                case 'logout': {
                    this.logout()
                    break
                }
            }
        },

        async saveUser(user) {
            this.showUserDialog = false
            await this.updateUser(user)
        }
    },
    computed: {
        contextItems() {
            return [
                {title: this.$t('users.dialog.editTitle'), action: 'editUserSettings'},
                {title: this.$t('tours.resetShownToursDialog'), action: 'resetShownTours'},
                {title: this.$t('actions.logout'), action: 'logout'}
            ]
        }
    }
}
</script>

<style scoped></style>
