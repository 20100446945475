const action = {
    CREATE_TASK: 'CREATE_TASK',
    DELETE_TASK: 'DELETE_TASK',
    UNDELETE_TASK: 'UNDELETE_TASK',
    CREATE_USER: 'CREATE_USER',
    DELETE_USER: 'DELETE_USER',
    UNDELETE_USER: 'UNDELETE_USER',
    UPDATE_USER: 'UPDATE_USER',
    CREATE_SHARED_LINK: 'CREATE_SHARED_LINK',
    UPDATE_SHARED_LINK: 'UPDATE_SHARED_LINK',
    DELETE_SHARED_LINK: 'DELETE_SHARED_LINK',
    SHARE_TASK: 'SHARE_TASK',
    SHARE_SCAN: 'SHARE_SCAN',
    UNSHARE_TASK: 'UNSHARE_TASK',
    UNSHARE_SCAN: 'UNSHARE_SCAN',
    REPARSE_SCAN: 'REPARSE_SCAN',
    REPARSE_ALL: 'REPARSE_ALL',
    DELETE_SCAN: 'DELETE_SCAN',
    UNDELETE_SCAN: 'UNDELETE_SCAN',
    CREATE_COMPANY: 'CREATE_COMPANY',
    UPDATE_COMPANY: 'UPDATE_COMPANY',
    DELETE_COMPANY: 'DELETE_COMPANY',
    UNDELETE_COMPANY: 'UNDELETE_COMPANY',
    DIFF_SCAN: 'DIFF_SCAN',
    REFRESH_TASK: 'REFRESH_TASK',
    MODIFY_PRODUCT: 'MODIFY_PRODUCT',
    MODIFY_CATEGORY: 'MODIFY_CATEGORY',
    CREATE_CATEGORY: 'CREATE_CATEGORY',
    DELETE_CATEGORY: 'DELETE_CATEGORY',
    UNDELETE_CATEGORY: 'UNDELETE_CATEGORY',
    DELETE_NOTIFY_SUBSCRIPTION: 'DELETE_NOTIFY_SUBSCRIPTION',
    UNDELETE_NOTIFY_SUBSCRIPTION: 'UNDELETE_NOTIFY_SUBSCRIPTION',
    ADD_NOTIFY_SUBSCRIPTION: 'ADD_NOTIFY_SUBSCRIPTION',
    EDIT_NOTIFY_SUBSCRIPTION: 'EDIT_NOTIFY_SUBSCRIPTION',
    UNSUBSCRIBE_ALL: 'UNSUBSCRIBE_ALL',
    UNSUBSCRIBE_ID: 'UNSUBSCRIBE_ID',
    UNSUBSCRIBE_RELEASE_NOTES_USER: 'UNSUBSCRIBE_RELEASE_NOTES_USER',
    GIVE_SCAN_CONSENT: 'GIVE_SCAN_CONSENT',
    RESEND_INVITATION: 'RESEND_INVITATION',
    MAIL_PARTNER_SALES_UPSELL: 'MAIL_PARTNER_SALES_UPSELL',
    MAIL_PARTNER_SALES_OFFER_AUTOMATION_BACKPORT: 'MAIL_PARTNER_SALES_OFFER_AUTOMATION_BACKPORT',
    MAIL_PARTNER_SALES_OFFER_AUTOMATION_1: 'MAIL_PARTNER_SALES_OFFER_AUTOMATION_1',
    MAIL_PARTNER_SALES_OFFER_AUTOMATION_2: 'MAIL_PARTNER_SALES_OFFER_AUTOMATION_2',
    MAIL_PARTNER_SALES_OFFER_AUTOMATION_PAUSED: 'MAIL_PARTNER_SALES_OFFER_AUTOMATION_PAUSED',
    CHANGE_CONFIG: 'CHANGE_CONFIG',
    CREATE_CONFIG: 'CREATE_CONFIG',
    DELETE_CONFIG: 'DELETECONFIG',
    UNDELETE_CONFIG: 'UNDELETE_CONFIG',
    MAIL_SCAN_CONSENT: 'MAIL_SCAN_CONSENT',
    CUSTOMER_TRIAL_ACCESS: 'CUSTOMER_TRIAL_ACCESS',
    CUSTOMER_TRIAL_REMINDER: 'CUSTOMER_TRIAL_REMINDER',
    CUSTOMER_TRIAL_FEEDBACK: 'CUSTOMER_TRIAL_FEEDBACK',
    PARTNER_OFFLINE_REMINDER_ACTIVITY: 'PARTNER_OFFLINE_REMINDER_ACTIVITY',
    INITIAL_SCAN_CONSENT: 'INITIAL_SCAN_CONSENT',
    SCAN_CONSENT_REMINDER: 'SCAN_CONSENT_REMINDER',
    REGISTRATION_REMINDER: 'REGISTRATION_REMINDER',
    REGISTRATION_REMINDED: 'REGISTRATION_REMINDED',
    SCAN_RERATE: 'SCAN_RERATE',
    TASK_RERATE: 'TASK_RERATE',
    PIPEDRIVE_ACTIVITY_LOW_SCAN_SCORE: 'PIPEDRIVE_ACTIVITY_LOW_SCAN_SCORE',

    QUESTIONNAIRE_ADDED_EMAIL: 'QUESTIONNAIRE_ADDED_EMAIL',
    QUESTIONNAIRE_REMOVED_EMAIL: 'QUESTIONNAIRE_REMOVED_EMAIL',
    QUESTIONNAIRE_ANSWERING_LOCKED: 'QUESTIONNAIRE_ANSWERING_LOCKED',
    QUESTIONNAIRE_ANSWERING_UNLOCKED: 'QUESTIONNAIRE_ANSWERING_UNLOCKED',
    QUESTIONNAIRE_ANSWER_EXPIRY_EMAIL: 'QUESTIONNAIRE_ANSWER_EXPIRY_EMAIL',
    QUESTIONNAIRE_UPDATED_EMAIL: 'QUESTIONNAIRE_UPDATED_EMAIL',
    QUESTIONNAIRE_REQUEST_REANSWER_EMAIL: 'QUESTIONNAIRE_REQUEST_REANSWER_EMAIL',
    QUESTIONNAIRE_ANSWERING_OVERDUE: 'QUESTIONNAIRE_ANSWERING_OVERDUE',

    DATASETFILE_EXPIRY_EMAIL: 'DATASETFILE_EXPIRY_EMAIL',
    GHOST_SUPPLIER_EMAIL_CHANGED: 'GHOST_SUPPLIER_EMAIL_CHANGED',
    VIEW_SUPPLIER_DETAILS_CHANGED: 'VIEW_SUPPLIER_DETAILS_CHANGED',

    DELETE_CONFIG_MAPPINGS: 'DELETE_CONFIG_MAPPINGS',
    UNDELETE_CONFIG_MAPPINGS: 'UNDELETE_CONFIG_MAPPINGS',

    DELETE_PRODUCT: 'DELETE_PRODUCT',
    UNDELETE_PRODUCT: 'UNDELETE_PRODUCT',

    DELETE_SCREENSHOT: 'DELETE_SCREENSHOT',
    UNDELETE_SCREENSHOT: 'UNDELETE_SCREENSHOT',

    DELETE_NOTIFY_CVE: 'DELETE_NOTIFY_CVE',
    UNDELETE_NOTIFY_CVE: 'UNDELETE_NOTIFY_CVE',

    DELETE_SHARED: 'DELETE_SHARED',
    UNDELETE_SHARED: 'UNDELETE_SHARED',

    DELETE_HOST_RESULT: 'DELETE_HOST_RESULT',
    UNDELETE_HOST_RESULT: 'UNDELETE_HOST_RESULT',

    DELETE_AUTH_METHODS: 'DELETE_AUTH_METHODS',
    UNDELETE_AUTH_METHODS: 'UNDELETE_AUTH_METHODS',

    DELETE_DATASETFILE: 'DELETE_DATASETFILE',
    UNDELETE_DATASETFILE: 'UNDELETE_DATASETFILE',

    NO_ACTIVE_NOTIFY_PIPEDRIVE_TASK: 'NO_ACTIVE_NOTIFY_PIPEDRIVE_TASK',

    TASK_NEXT_AT_CHANGED: 'TASK_NEXT_AT_CHANGED',
    TASK_CATEGORY_CHANGED: 'TASK_CATEGORY_CHANGED',

    PRICING_CATEGORY_MODIFIED: 'PRICING_CATEGORY_MODIFIED',
    PRICING_SUPPLIER_CATEGORY_CHANGED: 'PRICING_SUPPLIER_CATEGORY_CHANGED',
    PRICING_QUESTIONNAIRE_ADDED: 'PRICING_QUESTIONNAIRE_ADDED',
    PRICING_QUESTIONNAIRE_REMOVED: 'PRICING_QUESTIONNAIRE_REMOVED',
    PRICING_SUPPLIER_CREATED: 'PRICING_SUPPLIER_CREATED',
    PRICING_SUPPLIER_DELETED: 'PRICING_SUPPLIER_DELETED',
    PRICING_SCAN_CONSENT_CHANGED: 'PRICING_SCAN_CONSENT_CHANGED',
    PRICING_TASK_DRAFT_UNSET: 'PRICING_TASK_DRAFT_UNSET',
    SCAN_APPROVAL_GRANTED: 'SCAN_APPROVAL_GRANTED',
    PHISHING_TASK_ABUSE_REPORTED: 'PHISHING_TASK_ABUSE_REPORTED',

    NPS_VRM: 'NPS_VRM',
    NPS_CYBERRISK_ANALYSIS: 'NPS_CYBERRISK_ANALYSIS',
    NPS_MANAGEMENT_SCANS: 'NPS_MANAGEMENT_SCANS',

    AI_RESOURCE_CALCULATION: 'AI_RESOURCE_CALCULATION',

    // Module features- trial
    SECURE_PLUS_TRIAL: 'SECURE_PLUS_TRIAL',
    AI_HELPER_TRIAL: 'AI_HELPER_TRIAL',
    COMPLIANCE_MAPPING_TRIAL: 'COMPLIANCE_MAPPING_TRIAL',
    DOMAIN_SQUATTING_TRIAL: 'DOMAIN_SQUATTING_TRIAL',
    UPTIME_MONITORING_TRIAL: 'UPTIME_MONITORING_TRIAL',
    RESOURCE_CALCULATOR_TRIAL: 'RESOURCE_CALCULATOR_TRIAL',
    COMPLETE_PREVIEW_TRIAL: 'COMPLETE_PREVIEW_TRIAL',
    CUSTOM_MESSAGES_TRIAL: 'CUSTOM_MESSAGES_TRIAL',

    SECURE_PLUS_TRIAL_ENDING_REMINDER_EMAIL: 'SECURE_PLUS_TRIAL_ENDING_REMINDER_EMAIL',
    AI_HELPER_TRIAL_ENDING_REMINDER_EMAIL: 'AI_HELPER_TRIAL_ENDING_REMINDER_EMAIL',
    COMPLIANCE_MAPPING_TRIAL_ENDING_REMINDER_EMAIL: 'COMPLIANCE_MAPPING_TRIAL_ENDING_REMINDER_EMAIL',
    DOMAIN_SQUATTING_TRIAL_ENDING_REMINDER_EMAIL: 'DOMAIN_SQUATTING_TRIAL_ENDING_REMINDER_EMAIL',
    UPTIME_MONITORING_TRIAL_ENDING_REMINDER_EMAIL: 'UPTIME_MONITORING_TRIAL_ENDING_REMINDER_EMAIL',
    RESOURCE_CALCULATOR_TRIAL_ENDING_REMINDER_EMAIL: 'RESOURCE_CALCULATOR_TRIAL_ENDING_REMINDER_EMAIL',
    COMPLETE_PREVIEW_TRIAL_ENDING_REMINDER_EMAIL: 'COMPLETE_PREVIEW_TRIAL_ENDING_REMINDER_EMAIL',
    CUSTOM_MESSAGES_TRIAL_ENDING_REMINDER_EMAIL: 'CUSTOM_MESSAGES_TRIAL_ENDING_REMINDER_EMAIL',

    SECURE_PLUS_TRIAL_ENDED_EMAIL: 'SECURE_PLUS_TRIAL_ENDED_EMAIL',
    AI_HELPER_TRIAL_ENDED_EMAIL: 'AI_HELPER_TRIAL_ENDED_EMAIL',
    COMPLIANCE_MAPPING_TRIAL_ENDED_EMAIL: 'COMPLIANCE_MAPPING_TRIAL_ENDED_EMAIL',
    DOMAIN_SQUATTING_TRIAL_ENDED_EMAIL: 'DOMAIN_SQUATTING_TRIAL_ENDED_EMAIL',
    UPTIME_MONITORING_TRIAL_ENDED_EMAIL: 'UPTIME_MONITORING_TRIAL_ENDED_EMAIL',
    RESOURCE_CALCULATOR_TRIAL_ENDED_EMAIL: 'RESOURCE_CALCULATOR_TRIAL_ENDED_EMAIL',
    COMPLETE_PREVIEW_TRIAL_ENDED_EMAIL: 'COMPLETE_PREVIEW_TRIAL_ENDED_EMAIL',
    CUSTOM_MESSAGES_TRIAL_ENDED_EMAIL: 'CUSTOM_MESSAGES_TRIAL_ENDED_EMAIL',

    // Module features- subscription
    SECURE_PLUS_SUBSCRIPTION: 'SECURE_PLUS_SUBSCRIPTION',
    AI_HELPER_SUBSCRIPTION: 'AI_HELPER_SUBSCRIPTION',
    COMPLIANCE_MAPPING_SUBSCRIPTION: 'COMPLIANCE_MAPPING_SUBSCRIPTION',
    DOMAIN_SQUATTING_SUBSCRIPTION: 'DOMAIN_SQUATTING_SUBSCRIPTION',
    UPTIME_MONITORING_SUBSCRIPTION: 'UPTIME_MONITORING_SUBSCRIPTION',
    RESOURCE_CALCULATOR_SUBSCRIPTION: 'RESOURCE_CALCULATOR_SUBSCRIPTION',
    COMPLETE_PREVIEW_SUBSCRIPTION: 'COMPLETE_PREVIEW_SUBSCRIPTION',
    CUSTOM_MESSAGES_SUBSCRIPTION: 'CUSTOM_MESSAGES_SUBSCRIPTION',

    SECURE_PLUS_SUBSCRIPTION_ENDING_REMINDER_EMAIL: 'SECURE_PLUS_SUBSCRIPTION_ENDING_REMINDER_EMAIL',
    AI_HELPER_SUBSCRIPTION_ENDING_REMINDER_EMAIL: 'AI_HELPER_SUBSCRIPTION_ENDING_REMINDER_EMAIL',
    COMPLIANCE_MAPPING_SUBSCRIPTION_ENDING_REMINDER_EMAIL: 'COMPLIANCE_MAPPING_SUBSCRIPTION_ENDING_REMINDER_EMAIL',
    DOMAIN_SQUATTING_SUBSCRIPTION_ENDING_REMINDER_EMAIL: 'DOMAIN_SQUATTING_SUBSCRIPTION_ENDING_REMINDER_EMAIL',
    UPTIME_MONITORING_SUBSCRIPTION_ENDING_REMINDER_EMAIL: 'UPTIME_MONITORING_SUBSCRIPTION_ENDING_REMINDER_EMAIL',
    RESOURCE_CALCULATOR_SUBSCRIPTION_ENDING_REMINDER_EMAIL: 'RESOURCE_CALCULATOR_SUBSCRIPTION_ENDING_REMINDER_EMAIL',
    COMPLETE_PREVIEW_SUBSCRIPTION_ENDING_REMINDER_EMAIL: 'COMPLETE_PREVIEW_SUBSCRIPTION_ENDING_REMINDER_EMAIL',
    CUSTOM_MESSAGES_SUBSCRIPTION_ENDING_REMINDER_EMAIL: 'CUSTOM_MESSAGES_SUBSCRIPTION_ENDING_REMINDER_EMAIL',

    SECURE_PLUS_SUBSCRIPTION_ENDED_EMAIL: 'SECURE_PLUS_SUBSCRIPTION_ENDED_EMAIL',
    AI_HELPER_SUBSCRIPTION_ENDED_EMAIL: 'AI_HELPER_SUBSCRIPTION_ENDED_EMAIL',
    COMPLIANCE_MAPPING_SUBSCRIPTION_ENDED_EMAIL: 'COMPLIANCE_MAPPING_SUBSCRIPTION_ENDED_EMAIL',
    DOMAIN_SQUATTING_SUBSCRIPTION_ENDED_EMAIL: 'DOMAIN_SQUATTING_SUBSCRIPTION_ENDED_EMAIL',
    UPTIME_MONITORING_SUBSCRIPTION_ENDED_EMAIL: 'UPTIME_MONITORING_SUBSCRIPTION_ENDED_EMAIL',
    RESOURCE_CALCULATOR_SUBSCRIPTION_ENDED_EMAIL: 'RESOURCE_CALCULATOR_SUBSCRIPTION_ENDED_EMAIL',
    COMPLETE_PREVIEW_SUBSCRIPTION_ENDED_EMAIL: 'COMPLETE_PREVIEW_SUBSCRIPTION_ENDED_EMAIL',
    CUSTOM_MESSAGES_SUBSCRIPTION_ENDED_EMAIL: 'CUSTOM_MESSAGES_SUBSCRIPTION_ENDED_EMAIL'
}
Object.freeze(action)

module.exports = action
